@media print {

    #iconHamburgerIcon {
        display: none;
    }

    #btnEN, #btnFR, #btnOT, #btntoggleColorMode {
        display: none;
    }

    #textBtnIntroduction, #textBtnIndividualCalculator, #textBtnFeedback, #textBtnCountryCalculator {
        font-size: 15px;
    }

    .nav-link.active {
        display: block;
    }

    .nav-link {
        display: none;
    }

    .chakra-stack {
        text-align: center;
        width: 100%;
    }

    #select-country-caculator {
        display: none;
    }

    #page-footer {
        page-break-before: auto;
    }

    #ft-1, #ft-2, #ft-3, #ft-4 {
        
        display: block;
    }

    #ft-5, #ft-6, #ft-7, #ft-8 {
        display: none;
    }

    #info-country-caculator, .table-country-caculator {
        page-break-before: auto;
    }
    
    .btn-individual-caculator {
        display: none !important;
    }

   .introduction-card {
        page-break-after: always;
    }
}
