#google_translate_element img {
  display: none;
}
#google_translate_element span {
  display: none;
}
.goog-te-gadget {
  color: transparent !important;
  height: 30px;
  position: relative;
}

.goog-te-gadget select {
  color: #89bd55;
  height: 30px;
  background-color: transparent;
  border-radius: 10px;
  font-weight: bold;
}
/* #google_translate_element .goog-te-gadget-simple{
    background-color: transparent !important;
    border: none;
} */

/* #google-translate {
    display: none;
} */
